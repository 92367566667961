<template>
     <div v-if="noWallet" class="modal">
      <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">{{lang.get("INSTALL_WALLET")}}</div>
              <button @click="$emit('closeInstallModal')" data-cv="btn-close-switch-wallet"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content">
              <ul class="list-select list-select_wallet"  data-cy="list-select-wallet">
                <li v-if="getSelecteWallet === 'metamask'">
                        <div class="wallet_img">
                          <div class="wallet-metamask"></div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">Metamask</a>
                        <!-- <i v-if="getSelecteWallet === 'metamask'" class="i-check-full"></i> -->
                      </li> 
                       <!-- <li v-if="getSelecteWallet === 'binance'">
                        <div class="wallet_img">
                          <div class="wallet-binance"></div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp?hl=en" target="_blank">Binance Wallet</a>
                      </li>   -->
                    <!-- <li >
                        <div class="wallet_img">
                          <div class="wallet-metamask"></div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">Metamask</a>
                      </li> 
                      <li >
                        <div class="token_img">
                          <div class="token-a"></div>
                        </div>
                        <a href="https://trustwallet.com/" target="_blank">Trust Wallet</a>
                      </li>  -->
              </ul>
          </div>
      </div>
    </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
    props: ['noWallet'],
    data: function() {
      return {
        lang: new MultiLang(this),
      }
    },
    computed: {
      getSelecteWallet(){
        return window.localStorage.getItem("selectedWallet");
      }
    },
    mounted() {
      this.lang.init()
    }

}
</script>