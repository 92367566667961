import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentAddress: "",
    stakeTokens: [],
    lang: "en",
    stakesInfo: null,
    farmTokens: [],
    totalTokensNumber: 0,
    refNumber: 0,
    totalStake: 0,
    tokensTotalStake: {}
  },
  mutations: {
    setTotalStake(state, data){
      state.totalStake = data
    },
    setTokensTotalStake(state, data){
      state.tokensTotalStake = data
    },
    setStakeTokens(state, data){
      state.stakeTokens = data
    },
    setReferralsNumber(state, data){
      state.refNumber = data
    },
    setLanguage(state, lang) {
      state.lang = lang;
    },
    setCurrentAddress(state, address) {
      state.currentAddress = address;
    },
    setStakesInfo(state, data) {
      state.stakesInfo = data
    },
    setTotalTokensNumber(state, data){
      state.totalTokensNumber = data
    },
    setFarmTokens(state, data){
      state.farmTokens = data
    },
  },
  actions: {
    updateLanguage({ commit }, lang) {
      commit('setLanguage', lang);
      localStorage.setItem("lang", lang);
    },
  },
  modules: {
  },
  getters: {
    getLanguage(state) {
      return state.lang;
    },
    getFarmTokens(state){
      return state.farmTokens;
    },
    getStakeTokens(state){
      return state.stakeTokens;
    },
    getTokensTotalStake(state){
      return state.tokensTotalStake;
    }
  }
})
