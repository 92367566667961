<template>
  <div id="app">
    <connect-wallet-modal :noWallet="noWallet" :showWalletOptions = "showWalletOptions" @close="showWalletOptions = false, noWallet = false" @setWalletAndAddress="setWalletOption(getWalletOption())" />
    <install-wallet-modal :noWallet="noWallet"  @closeInstallModal="noWallet = false"/>
    <router-view  @changeWalletRequest="showWalletOptions = true" @setWalletAndAddress="setWalletOption(getWalletOption())"/>

    <footer class="footer">
      <div class="container">
        <div>Copyright © 2021. All rights reserved.</div>
      </div>
    </footer>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import {mapState} from 'vuex';
import ConnectWalletModal from '../src/components/modalWindows/ConnectWalletModal';
import Core from "../src/core/core.js";
import ReferralController from "./controllers/ReferralController";
import InstallWalletModal from './components/modalWindows/InstallWalletModal.vue';

export default {
  components: {
    ConnectWalletModal,
    InstallWalletModal
  },
  data: function() {
    return {
      selectedWallet: "metamask",
      showWalletOptions: false,
      noWallet: false,
      currentUserAddress: "",
      walletUnlocked: null,
      lang: new MultiLang(this),
      showMobileMenu: false,
      showHowToGetWallet: false
    }
  },
   methods: {
    checkWalletOption(wallet) {
      let _this = this;

      if(wallet === 'metamask'){
              window.ethereum
                .request({ method: 'eth_accounts' })
                .then(handleAccountsChanged)
                .catch((err) => {
                  console.error(err);
                });

              window.ethereum.on('accountsChanged', handleAccountsChanged);

            function handleAccountsChanged(accounts) {
  
              if (accounts.length > 0) {

                _this.walletUnlocked = true;
              }
            }

      }
    },

    setWalletOption(wallet) {
      let _this = this;
      
      this.selectedWallet = wallet;
      let currentAccount = localStorage.getItem("address");
      if(wallet){
        localStorage.setItem('selectedWallet', wallet);
      }
      if(window.ethereum && this.selectedWallet === 'metamask'){
              window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then(handleAccountsChanged)
                .catch((err) => {
                  console.error(err);
                });

              // Note that this event is emitted on page load.
              // If the array of accounts is non-empty, you're already
              // connected.
              window.ethereum.on('accountsChanged', handleAccountsChanged);

            // For now, 'eth_accounts' will continue to always return an array
            function handleAccountsChanged(accounts) {
              if (accounts.length === 0) {

                _this.walletUnlocked = false;
                localStorage.removeItem('address')
                _this.$store.commit("setCurrentAddress", "")

                // MetaMask is locked or the user has not connected any accounts
                // alert('Please connect to MetaMask.');
              } else if (accounts[0] !== currentAccount) {
                currentAccount = accounts[0];
                localStorage.setItem("address", currentAccount);
                
                      // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                location.reload();
              }else if (accounts.length > 0) {
                _this.walletUnlocked = true;
              }
            }
      }else{
        _this.noWallet=true;
      }
    },



    getWalletOption() {
      const selWallet = localStorage.getItem('selectedWallet');
      if(!selWallet) {
        this.showWalletOptions = true;
        return null;
      }else {
        return selWallet;
      }
    },

    

    handleChainChanged(_chainId) {
      // We recommend reloading the page, unless you must do otherwise

      window.location.reload();
    }
    

  },
  computed: {
    ...mapState(['currentAddress']),

    addressShort() {
      if(this.currentAddress){
        return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length)
      }
      }
  },
  async mounted () {
     this.lang.init();

    window.addEventListener("message", async function (e) {
        if(e.data && e.data.data && e.data.data.data && e.data.data.data.method === "metamask_unlockStateChanged") {
          this.walletUnlocked = e.data.data.data.params.isUnlocked;
        }
    })

    // let connected = await window.ethereum.isConnected();
    // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    // if(connected && chainId !== "0x61") {

    // }
    
    let tronConnectAttemptsCounter = 0;
    let _this = this;
    ReferralController.setReferrerAddressIfExists(this);
    const walletOpiton = _this.getWalletOption();
    setTimeout(async function initContract() {
      try {
            _this.$root.core = new Core(_this)

            
        if(walletOpiton) {
          if(walletOpiton === 'metamask' && !window.ethereum) {
            throw Error('No Metamask extension found');
          }

          if(window.ethereum) {
            //won't commit address without these checks
            if(window.ethereum) {
              window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
            }
            
            let currentAccount = localStorage.getItem('address');
            _this.$store.commit("setCurrentAddress", currentAccount);

            _this.setWalletOption(_this.getWalletOption())

          
            // _this.$root.core.updateSiteStats(50000);
            // _this.$root.core.getCurrentRate();
            
            // if(currentAccount){
            //   _this.$root.core.updateUserStatsAndTokensBalance(5000, currentAccount);
              
            // }

            let result = await _this.$root.core.init();
            if(result == undefined){
              throw Error();
            }
            _this.$root.core.getSiteData();
            _this.$root.core.getUserReward(currentAccount)
            _this.$root.core.updateUserInfo(currentAccount)


            

          }else{
            _this.noWallet = true;
            _this.$root.core = new Core(_this)
            // _this.$root.core.updateSiteStats(50000);
            // _this.$root.core.getCurrentRate();
          }
        }else {
          // _this.showWalletOptions = true;
          _this.$root.core = new Core(_this)
          // _this.$root.core.updateSiteStats(50000);
          // _this.$root.core.getCurrentRate();
          
        }
      
      } catch (ex) {
        // console.log(ex);
        tronConnectAttemptsCounter++;
        if(tronConnectAttemptsCounter > 5 && (ex.message == "No Metamask extension found")){
          _this.noWallet = true;
          _this.$root.core = new Core(_this)
          // _this.$root.core.updateSiteStats(50000);
          return;
        }

        if (tronConnectAttemptsCounter > 10) {

          if (window.ethereum == undefined && window.BinanceChain == undefined) {

            _this.$root.core = new Core(_this)
            // _this.$root.core.updateSiteStats(50000);

          } else {
            if(_this.selectedWallet === 'metamask'){
                 const params = [{
                chainId: '0x13881',
                chainName: 'Matic TestNet',
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'Matic',
                  decimals: 18
                },
                rpcUrls: ['https://rpc-mumbai.matic.today'],
                blockExplorerUrls: ['https://polygon-explorer-mumbai.chainstacklabs.com']

              }]
              const res = confirm(`Please switch network to ${params[0].chainName}.`)
              if(res) {
                ethereum.request({method: "wallet_addEthereumChain", params})
              }
            }
            // }else if(_this.selectedWallet === 'binance'){

            //   const params = [{
            //     chainId: '0x80001',
            //     chainName: 'Matic TestNet',
            //     nativeCurrency: {
            //       name: 'Matic',
            //       symbol: 'Matic',
            //       decimals: 18
            //     },
            //     rpcUrls: ['https://rpc-mumbai.matic.today'],
            //     blockExplorerUrls: ['https://polygon-explorer-mumbai.chainstacklabs.com']

            //   }]
            //   const res = confirm(`Please switch network to ${params[0].chainName}.`)
            //   if(res) {
            //     BinanceChain.switchNetwork("bsc-testnet")
            //   }
            // }
          }
        } else {
          setTimeout(initContract, 500);
        }
      }

      
    }, 300);
   

  },

  watch: {

    walletUnlocked: function () {
      let _this = this;
      setTimeout(function check() {
        if(_this.walletUnlocked === false) {
          _this.checkWalletOption(_this.selectedWallet)
        setTimeout(check, 1000)
        }else if (_this.walletUnlocked === true) {
      }
      },0);
    }

    
  }
}


</script>
