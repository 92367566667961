import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTippy, {TippyComponent} from 'vue-tippy';
import '../src/assets/style/main.css'
import Clipboard from 'v-clipboard'

Vue.config.productionTip = false
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
      modifiers: {
          preventOverflow: {
              enabled: false
          }
      }
  }
});

Vue.component("tippy", TippyComponent);
Vue.use(Clipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
