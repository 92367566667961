<template>
      <div v-if="showWalletOptions" class="modal">
      <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">{{lang.get("WALLET_NEEDED")}}</div>
              <button @click="$emit('close')" data-cv="btn-close-switch-wallet"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content">
              <ul class="list-select list-select_wallet"  data-cy="list-select-wallet">
                <li @click="setupDesiredWallet('metamask'), $emit('setWalletAndAddress')">
                        <div class="wallet_img">
                          <div class="wallet-metamask"></div>
                        </div>
                        <a>Metamask</a>
                        <i v-if="getSelecteWallet === 'metamask'" class="i-check-full"></i>
                      </li> 
                       <!-- <li @click="setupDesiredWallet('binance'), $emit('setWalletAndAddress')">
                        <div class="wallet_img">
                         <div class="wallet-binance"></div>
                        </div>
                        <a>Binance Wallet</a>
                        <i v-if="getSelecteWallet === 'binance'" class="i-check-full"></i>
                      </li>   -->
                    <!-- <li >
                        <div class="wallet_img">
                          <div class="token-a"></div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">Metamask</a>
                      </li> 
                      <li >
                        <div class="token_img">
                          <div class="token-a"></div>
                        </div>
                        <a href="https://trustwallet.com/" target="_blank">Trust Wallet</a>
                      </li>  -->
              </ul>
          </div>
      </div>

    </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
  name: "ConnectWalletModal",
  props: ['showWalletOptions'],
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  computed: {
    getSelecteWallet(){
      return window.localStorage.getItem("selectedWallet");
    }
  },
  methods: {
    setupDesiredWallet(walletOption){
      if(walletOption) {
        window.localStorage.setItem('selectedWallet', walletOption)
      }
    }
  },
  mounted() {
  this.lang.init()
}
}
</script>