<template>
        <header >
                <div class="container">
                    <div >
                    <div v-if="$route.name==='Home'" class="left-part" data-cy='logo' >
                        <div class="logo" > <a href="#home"></a></div>
                        <div class="label_wallet">Polygon (matic)</div>
                    </div>
                    <div v-else class="left-part" >
                        <a href="http://188.166.127.195"><div class="logo" ></div></a>
                        <div class="label_wallet">Polygon (matic)</div>
                    </div>

                    <ul v-if="$route.name==='Home'" class="header-list" data-cy="list-header-items">
                        <li><a href="#home">{{lang.get("HOME")}}</a></li>
                        <li ><a href="#bRing_features">{{lang.get("BRING_FEATURES")}}</a></li>
                        <li><a href="#bRing_pools">{{lang.get("BRING_POOLS")}}</a></li>
                        <li><a href="#questions">{{lang.get("FAQ")}}</a></li>
                        <li><a href="#our_partners">{{lang.get("OUR_PARTNERS")}}</a></li>
                        <li><a href="#roadmap">{{lang.get("ROADMAP")}}</a></li>
                    </ul>
                    <ul v-else class="header-list" data-cy="list-header-items-2">
                    </ul>

                    <div v-if="$route.name==='Home'">
                        <div v-if="currentAddress" class="right-part">
                            <div @click="$emit('changeWalletRequest')" class="button button_wallet" data-cv="btn-wallet"> 
                            {{addressShort}}
                            <i class="i-balance" style="margin-left: 5px ; font-size: 1.6em"></i>
                            </div> 
                            <a href="https://bringfinance.typeform.com/to/zXmXne58" target="_blank"><button class="button button_add-token">Add token</button></a>
                            
                            <a @click="$router.push({name: 'pool'})"><button v-if="currentAddress"  class="button button_launch-app" data-cv="btn-launch-app">{{lang.get("LAUNCH_APP")}}</button></a>
                        </div>
                        <div v-else class="right-part">
                            <div  @click="$emit('connectWallet')"  class="button button_connect-wallet" data-cv="btn-connect-wallet-header">
                                {{lang.get("CONNECT_WALLET")}} 
                            </div> 
                        </div>
                    </div>
                    <div v-else>
                        <div class="right-part">                             
                            <!-- <lang-menu /> -->
                            <div class="wallet">
                                <div v-if="currentAddress" @click="$emit('changeWalletRequest')" class="button button_wallet" data-cv="btn-current-address">
                                  {{addressShort}} 
                                  <i class="i-balance" style="margin-left: 5px ; font-size: 1.6em"></i>
                                </div>
                                <div  v-else  @click="$emit('connectWallet')" class="button button_connect-wallet" data-cv="btn-connect-wallet-2"> 
                                    {{lang.get("CONNECT_WALLET")}}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div class="right-part right-part_mobile">
                        <div @click="showMobileMenu=true" class="closed_menu" :class="{active: !showMobileMenu}" data-cy="closed-menu">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H18V2H0V0ZM0 7H12V9H0V7ZM0 14H18V16H0V14Z" fill="white"/>
                            </svg>

                        </div>
                        <div class="opened_menu" :class="{active: showMobileMenu}">
                        <i @click="showMobileMenu = false" class="i-close" data-cy="opened-menu"></i>
                        </div>
                          <div class="mobile_menu">
                            <div class="mobile_menu-header">
                              <div class="container" >
                                <div>
                                  <div v-if="$route.name==='Home'"  class="logo"><a @click="showMobileMenu = false" href="#home" data-cy='mobilemenu-logo'></a></div>
                                  <div v-else class="logo" @click="$router.push({name: 'Home'})"><a @click="showMobileMenu = false" href="#home"></a></div>
                                </div>
                              </div>
                            </div>
                            <div class="mobile_menu-content">
                              <div class="mobile_menu-section">
                                <div class="container">

                                <ul v-if="$route.name==='Home'" class="header-list header-list_mobile" data-cy="list-menu-items">
                                    <!-- <li @click="showMobileMenu = false"><a href="#home">{{lang.get("HOME")}}</a></li> -->
                                    <li @click="showMobileMenu = false" ><a href="#bRing_features">{{lang.get("BRING_FEATURES")}}</a></li>
                                    <li @click="showMobileMenu = false"><a href="#bRing_pools">{{lang.get("BRING_POOLS")}}</a></li>
                                    <!-- <li @click="showMobileMenu = false"><a href="#our_partners">{{lang.get("OUR_PARTNERS")}}</a></li> -->
                                    <li @click="showMobileMenu = false"><a href="#questions">{{lang.get("FAQ")}}</a></li>
                                    <li @click="showMobileMenu = false"><a href="#roadmap">{{lang.get("ROADMAP")}}</a></li>
                                </ul>
                                <ul v-else class="header-list header-list_mobile" data-cy="list-menu-cabinet-items">
                                        <router-link
                                          tag="li"
                                          :to="{ name: 'pool' }"
                                        >
                                          <a @click="showMobileMenu = false">{{lang.get("POOL")}}</a>
                                        </router-link>

                                        <router-link
                                          tag="li"
                                          :to="{ name: 'stake' }"
                                        >
                                          <a @click="showMobileMenu = false"> {{lang.get("MY_STAKE")}}</a>
                                        </router-link>

                                        <router-link
                                          tag="li"
                                          :to="{ name: 'reward' }"
                                        >
                                          <a @click="showMobileMenu = false">{{lang.get("MY_REWARDS")}}</a>
                                        </router-link>
                                </ul>

                                </div>
                              </div>
                              <div class="mobile_menu-section">
                                <div class="container">
                                <div v-if="currentAddress" class="right-part right-part_mobile">
                                    <div @click="$emit('changeWalletRequest')"  class="button button_wallet"> 
                                    {{addressShort}}<i class="i-balance" style="margin-left: 5px ; font-size: 1.6em"></i>
                                    </div> 
                                    <a href="https://bringfinance.typeform.com/to/zXmXne58" target="_blank"><button class="button button_add-token">Add token</button></a>
                            
                                    <button v-if="$route.name==='Home'" @click="$router.push({name: 'pool'})" class="button button-launch-app-to-pool" data-cv="btn-launch-app">{{lang.get("LAUNCH_APP")}}</button>
                                </div>
                                <div v-else class="right-part right-part_mobile">
                                    <div  @click="$emit('connectWallet'), showMobileMenu = false"  class="button button_connect-wallet">

                                    {{lang.get("CONNECT_WALLET")}} 
                                    </div> 
                                </div>

                                </div>
                              </div>
                              <div class="mobile_menu-section">
                                <div class="container">
                                <h4>{{lang.get("SOCIAL_NETWORKS")}} </h4>
                                    <div class="links-main links-main_mobile">
                                        <div class="container-link" data-cy="list-links-mobile"> 
                                        <div><a href="https://twitter.com/BringFinance" target="_blank"><span class="twitter"></span></a></div>
                                        <div><a href="https://medium.com/@BringFinance" target="_blank"><span class="medium"></span></a></div>
                                        <div><a href="https://t.me/bRing_community"  target="_blank"><span class="telegram"></span></a></div>
                                        <div><a href="https://t.me/bRing_channel" target="_blank"><span class="telegram speaker"> <i class="i-speaker"></i></span></a></div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="mobile_menu-section">
                                <!-- <div class="container">
                                <h4>{{lang.get("LANGUAGES")}}</h4>
                                    <div class="lang-main_mobile">
                                        <div class="container-lang" data-cv="list-lang-mobile"> 
                                          <-- <button class="active">En</button>
                                          <button>En</button>
                                          <button>En</button>
                                          <button>En</button> -->
                                    <!-- <lang-menu /> 
                                        </div>
                                    </div>

                                </div> -->
                              </div>
                            </div>
                          </div>
                    </div>

                    </div>
            </div>

        </header>
        <!-- <header v-else>
          <div class="container">
            <div >
              <div class="left-part">
                  <div @click="$router.push({name: 'Home'})" class="logo"></div>
              </div>

              <div class="right-part">
                  <div class="wallet">
                      <div v-if="currentAddress" class="button button_wallet">
                        {{addressShort}}
                      </div>
                      <div  v-else  @click="connectWallet" class="button button_connect-wallet">
                          Connect Wallet
                      </div>
                  </div>
              </div>
            </div>
          </div>
      </header> -->
</template>

<script>
import {mapState} from 'vuex';
import MultiLang from "@/core/multilang";
import LangMenu from './LangMenu.vue';
export default {
    components: { LangMenu },
    data: function() {
        return{
          lang: new MultiLang(this),
          showMobileMenu: false,

        }
    },
    computed: {
        ...mapState(['currentAddress']),
        addressShort(){

        if(this.currentAddress) {
            return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length)
        }

        }
    },
    methods: {
    //     connectWallet() {
    //   try {
    //     let currentAccount = localStorage.getItem("address")
    //     if(window.ethereum){
    //             window.ethereum
    //               .request({ method: 'eth_requestAccounts' })
    //               .then(handleAccountsChanged)
    //               .catch((err) => {
    //                 if(err.code == "-32002"){
    //                   alert('Proceed to your metamask extension')
    //                 };
    //               });

    //             // Note that this event is emitted on page load.
    //             // If the array of accounts is non-empty, you're already
    //             // connected.
    //             window.ethereum.on('accountsChanged', handleAccountsChanged);

    //           // For now, 'eth_accounts' will continue to always return an array
    //           function handleAccountsChanged(accounts) {
    //             if (accounts.length === 0) {

    //               localStorage.removeItem('address')
    //               _this.$store.commit("setCurrentAddress", "")

    //               // MetaMask is locked or the user has not connected any accounts
    //               // alert('Please connect to MetaMask.');
    //             } else if (accounts[0] !== currentAccount) {
    //               currentAccount = accounts[0];
    //               localStorage.setItem("address", currentAccount);
    //                     // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
    //               location.reload();
    //             }
    //           }
    //     }else {
    //       this.$emit('showInstallWalletModal')
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    },
    mounted() {
      this.lang.init();
    }
}
</script>