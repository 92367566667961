import {ethers} from 'ethers';
import Config from '../../Config.json'

import {abi} from "../abi/contractAbi";
import {V2} from "../abi/V2";


export default class Core {
    constructor(vueContext){
        this.context = vueContext
        this.farmAddress = Config.FARM_ADDRESS
        this.farmAddress_2 = Config.FARM_ADDRESS_2
        this.farmAdminAddress = Config.FARM_ADMIN_ADDRESS
        this.defaultReferrer = Config.DEFAULT_REFERRER
        this.init()
    }

    async init() {
        if(!this.contract){
            if(window.localStorage.getItem("selectedWallet") === "metamask" && window.ethereum){

                if(window.ethereum.chainId === "0x13881" || window.ethereum.chainId == 80001) {
                    let provider =  new ethers.providers.Web3Provider(window.ethereum);
                    this.providerAddress = provider;
                    this.signer = provider.getSigner();
                    this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(this.signer);
                    // this.V2 = new ethers.Contract(this.farmAddress_2,  V2, provider).connect(this.signer);
                
                    return this.V1;
                }else {
                    throw new Error("Please change the network in Metamask to mainnet")
                }
                
            }else {
                let provider = new ethers.providers.JsonRpcProvider("https://rpc-mumbai.matic.today");;
                this.providerAddress = provider;
                // this.signer = provider.getSigner();
                this.V1 = new ethers.Contract(this.farmAddress,  abi, provider);

                return this.V1;
            }
            
        }

        return this.V1;
    }

    getSiteData(period=360000){
        
        let _this = this;

        setTimeout(async function tick() {

            try {
                // let totalTokensNumber = await _this.totalTokensCount()
                // let farmTokens = await _this.getFarmingTokens()
                let stakeTokens = Config.stakeTokens;
                let tokens = Config.tokens

                let tokensTotalStake = {
                    V1: {},
                    // V2: {}
                }

                for(let i = 0; i < tokens.length; i++){
                   let tokenAddress = tokens[i].address
                   let {V1TotalStake} = await _this.getTotalStaking(tokens[i].address)

                   tokensTotalStake.V1[tokenAddress.toLowerCase()] = V1TotalStake
                //    tokensTotalStake.V2[tokenAddress.toLowerCase()] = V2TotalStake
                } 

                // _this.context.$store.commit('setTotalTokensNumber', totalTokensNumber);
                _this.context.$store.commit('setStakeTokens', stakeTokens);
                _this.context.$store.commit('setTokensTotalStake', tokensTotalStake);



            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);
            setTimeout(tick, 300);
            }
        }, 300);

        
    }

    updateUserInfo(userAddress, period=10000){
        let _this = this;

        setTimeout(async function tick() {
            try {
                let stakeTokens = _this.context.$store.getters.getStakeTokens
                let tokensTotalStake = _this.context.$store.getters.getTokensTotalStake;
                

                //TODO add alternative check
                // if(!farmTokens.length){
                //     throw new Error
                // }


                    //getting raw stake data from all contracts 
                  let V1StakingDataRaw =  await _this.getStakingDetails(userAddress, "V1");
                //   let V2StakingDataRaw =  await _this.getStakingDetails(userAddress, "V2");

                  ///getting  stake rewards from all contracts

                  for (let i=0; i < V1StakingDataRaw.length; i++){
                    if(!V1StakingDataRaw[i].isActive){

                      let stakeAmount = await _this.getStakeAmount(userAddress, V1StakingDataRaw[i].stakeId, "V1");
                      V1StakingDataRaw[i].stakeAmount = stakeAmount
            

                    }
                  }

                //   for (let i=0; i < V2StakingDataRaw.length; i++){
                //     if(!V2StakingDataRaw[i].isActive){

                //       let stakeAmount = await _this.getStakeAmount(userAddress, V2StakingDataRaw[i].stakeId, "V2");
                //       V2StakingDataRaw[i].stakeAmount = stakeAmount
            

                    // }
                //   }

                  //getting stake data for each pool contract

                  let V1StakingData = V1StakingDataRaw.map(el => {
  
                    let stakeTokenAddress = el.tokenAddress;
                    let farmTokensList = stakeTokens.find(stakeToken => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.poolVersion == "V1").farmTokensList;
                    
                    let stakeTokenData = Config.stakeTokens.find(stakeToken => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase()  && stakeToken.poolVersion == "V1");
                    
                    return {
                      ...el,
                      name: stakeTokenData.name,
                      maxStake: stakeTokenData.totalMaxStake,
                      userMaxStake: stakeTokenData.userMaxStake,
                      decimals: stakeTokenData.decimals,
                      farmTokensList,
                      poolVersion: "V1"
                    }
                  })

                //   let V2StakingData = V2StakingDataRaw.map(el => {
  
                //     let stakeTokenAddress = el.tokenAddress;
                //     let farmTokensList = stakeTokens.find(stakeToken => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.poolVersion == "V2").farmTokensList;
                    
                //     let stakeTokenData = Config.stakeTokens.find(stakeToken => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.poolVersion == "V2");
                    
                //     return {
                //       ...el,
                //       name: stakeTokenData.name,
                //       maxStake: stakeTokenData.totalMaxStake,
                //       userMaxStake: stakeTokenData.userMaxStake,
                //       decimals: stakeTokenData.decimals,
                //       farmTokensList,
                //       poolVersion: "V2"
                //     }
                //   })
                  
                  let V1StakingDataWithReward =  await _this.calcRewardForStakeTokens(V1StakingData, tokensTotalStake.V1)
                //   let V2StakingDataWithReward = await _this.calcRewardForStakeTokens(V2StakingData, tokensTotalStake.V2)
                  

                //   let stakingData = [...V1StakingDataWithReward, ...V2StakingDataWithReward]
                  let stakingData = [...V1StakingDataWithReward]

                  stakingData.sort((a, b) => b.startTime - a.startTime)

                  let refNumber = await _this.getReferrals(userAddress, "V1");
                //   refNumber += await _this.getReferrals(userAddress, "V2");
                  _this.context.$store.commit('setReferralsNumber', refNumber)
                  _this.context.$store.commit('setStakesInfo', stakingData)


            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);
            setTimeout(tick, 300);
            }
        }, 300);
        

    }

    // async getFarmingTokens() {
    //     let stakeTokensAddresses = Config.stakeTokens;
    //     let farmTokensAddresses = []

    //     // for (let i = 0; i < stakeTokensAddresses.length; i++){
    //     //     let address = await this.farm.getPoolTokens(stakeTokensAddresses[i].address);
    //     //     farmTokensAddresses.push(address)
    //     // }

    //     let allTokens = (stakeTokensAddresses.map((token, index) => {
    //         let farmTokensData = farmTokensAddresses[index].map(el => {
    //             let farmTokenData = Config.stakeTokens.find(farmToken =>  farmToken.address === el).farmTokens;
    //             return farmTokenData
    //             })

    //         return {
    //             tokenName: token.name,
    //             tag: token.tag,
    //             tokenAddress: token.address,
    //             tokenDecimals: token.decimal,
    //             userMinStake: token.userMinStake,
    //             userMaxStake: token.userMaxStake,
    //             totalMaxStake: token.totalMaxStake,
    //             // lockableDays: token.lockableDays,
    //             // optionableStatus: token.optionableStatus,
    //             farmTokensData: farmTokensData,
    //         }

    //     }))

    //     return allTokens
    // }

    async calcRewardForStakeTokens(stakingData, totalStakes) {
        const poolStartTime = Config.POOL_START_TIME
                
        let poolEndTime = poolStartTime + (86400 * Config.STAKE_DAYS) //seconds in 24 hours * 90 days
        let currentTime = Math.floor(new Date().getTime() / 1000);

        for (let i=0; i<stakingData.length; i++) {
            if(stakingData[i].isActive == true){
              let tokenAddress = stakingData[i].tokenAddress;
              let stakeAmount = stakingData[i].stakeAmount;
              let stakeDate = stakingData[i].startTime;
              let totalStakesForStakeToken = totalStakes[tokenAddress.toLowerCase()]


              let farmTokensReward = [];
              let farmTokemsPossibleRewards = [];
              for(let j = 0; j< stakingData[i].farmTokensList.length; j++){
                const interval = stakingData[i].farmTokensList[j].interval * 86400;
                if(poolEndTime > currentTime){
                    poolEndTime = currentTime
                }
                
                let possibleRewardEarned;
                

                const userHourlyReard = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)


                const possibleReward =
                    Number(userHourlyReard * 24) *
                    (Config.STAKE_DAYS - stakingData[i].farmTokensList[j].interval);

                const fivePercFee = possibleReward * 5 / 100
                
                if(stakingData[i].referrer != "0x0000000000000000000000000000000000000000"){
                    possibleReward -= fivePercFee;
                }else {
                    possibleReward -= (fivePercFee * 2);
                }

            
               if(interval === 86400 && currentTime >= (stakeDate + 3600)){ //rewards are accesseble after 1hour
                    const rewardForOneDay = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)


                    possibleRewardEarned  = Math.floor((poolEndTime - stakeDate) / 3600) * rewardForOneDay;
                    
                    let refReward = possibleRewardEarned * 5 / 100;

                    if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                        possibleRewardEarned -= refReward;
                    }else {
                        possibleRewardEarned -= (refReward*2);
                    }
               }else if (interval > 86400 && currentTime >= (stakeDate + interval + 3600)){
                    const rewardForOneDay = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)

                    let noOfDaysInHours = Math.floor((poolEndTime - stakeDate) / 3600);
                    possibleRewardEarned  = Math.floor(noOfDaysInHours - ((interval - 86400) / 3600)) * rewardForOneDay;

                    let refReward = possibleRewardEarned * 5 / 100;

                    if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                        possibleRewardEarned -= refReward;
                    }else {
                        possibleRewardEarned -= (refReward*2);
                    }
               }else {
                    possibleRewardEarned = 0
               }
               

              farmTokensReward.push(possibleRewardEarned);
              farmTokemsPossibleRewards.push(possibleReward)


              }
              
              stakingData[i]['farmTokensReward'] =  farmTokensReward 
              stakingData[i]['farmTokensPossibleReward'] = farmTokemsPossibleRewards
            }
            continue
          }

          return stakingData
    }

    async getStakingDetails(userAddress, contractVersion) {
        if(!this[contractVersion]) {
            await this.init()
        }


        let resultRaw = await this[contractVersion].viewStakingDetails(userAddress);
        let result = []


    
        function getCol(array, col){
            var column = [];
            for(var i=0; i<array.length; i++){
               column.push(array[i][col]);
            }
            return column; 
         }
             
        for(let i = 0; i < resultRaw[0].length; i++){
            
            let column = getCol(resultRaw, i)


            let stakeObj = {
                referrer: column[0],
                tokenAddress: column[1],
                isActive: column[2],
                stakeId: Number.parseInt(column[3]),
                stakeAmount: Number(column[4]) / 10 ** 18,
                startTime: Number.parseInt(column[5]),
            }
            result.push(stakeObj);
        }
        result.sort((a, b) => b.startTime - a.startTime)

        return result;
        
    }

    async getUserTotalStaking(userAddress, tokenAddress, contractVersion) {
        let resultRaw = await this[contractVersion].userTotalStaking(userAddress, tokenAddress);

    }

    async stake(tokenAddress, amount, decimals, ref="", contractVersion) {
        if(!ref) {
            ref = this.defaultReferrer;
        }

        amount =  ethers.utils.parseUnits(`${amount}`, decimals)

        let rawTransactions = await this[contractVersion].stake(ref, tokenAddress, amount, {gasPrice: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})



        return rawTransactions;
    }

    async unstake(userAddress, stakeId, contractVersion){
        let rawTransactions = await this[contractVersion].unstake(userAddress, stakeId, {gasPrice: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})

        return rawTransactions;
    }

    async approve(tokenContract, amount, decimals, contractVersion){

        amount = ethers.utils.parseUnits(`${amount}`, decimals)

        const rawTransaction = await tokenContract.approve(this[contractVersion].address, amount)
        return rawTransaction;
    }

    async totalTokensCount(contractVersion){
        const result = await this[contractVersion].totalTokensCount();
        return result;
    }

    async getPoolStartTime(contractVersion) {
        const resultRaw = await this[contractVersion].poolStartTime();
        return Number.parseInt(resultRaw._hex)
    }

    async getTotalStaking(poolAddress) {
        const pool1ResultRaw = await this.V1.totalStaking(poolAddress);
        const V1TotalStake = Number(ethers.utils.formatUnits(pool1ResultRaw._hex));

        // const pool2ResultRaw = await this.V2.maxTotalStaked(poolAddress); //Todo max total staked
        // const V2TotalStake = Number(ethers.utils.formatUnits(pool2ResultRaw._hex));

        return {
            V1TotalStake,
            // V2TotalStake
        };
    }
    async getTotalStakingOnSpecificContract(poolAddress, tokenContract) {
        const poolResultRaw = await this[tokenContract].totalStaking(poolAddress);
        const totalStake = Number(ethers.utils.formatUnits(poolResultRaw._hex));

        return totalStake
    }

    async checkReferrer(refAddress, contractVersion) {
        const result = await this[contractVersion].isActiveUser(refAddress);

        return result;
    }

    async getReferrals(userAddress, contractVersion) {
        if(!this[contractVersion]){
            await this.init()
        }

        const result = await this[contractVersion].users(userAddress);
        return Number.parseInt(result._hex);
    }

    async getUserReward(userAddress, period=60000) { /* miliseconds */
            let _this = this;

            setTimeout(async function tick() {
            try {
                let blockchainLastBlock = await _this.providerAddress.getBlockNumber()
                let startBlock = Config.START_BLOCK;
                let lastBlock
                //берем ивенты
                let events = JSON.parse(localStorage.getItem(`${userAddress}`));
                //если пусто
                if(!events) {
                    lastBlock = Number(startBlock);
                }else if (events && events.from){
                    //если есть ивенты, сканируем от последнего записанного блока по +=1000
                    startBlock = Number(events.from) + 1

                }


              //устанавливаем по какой блок сканировать ивенты
                for (let from = startBlock; from <= blockchainLastBlock;) {

                if(from > blockchainLastBlock){
                    from = blockchainLastBlock;
                }
                let to = (from + 1000) <= blockchainLastBlock ? from + 1000 : blockchainLastBlock


                // let filterClaim = _this[contractVersion].filters.Claim(userAddress);
                // filterClaim.fromBlock = from;
                // filterClaim.toBlock = to;
                // let eventsRegister = await _this.providerAddress.getLogs(filterClaim)

                let V1ResultClaimRaw = await _this.V1.queryFilter("Claim", from, to)
                V1ResultClaimRaw.forEach(el => {
                    return el.poolVersion = "V1"
                })


                // let V2ResultClaimRaw = await _this.V2.queryFilter("Claim", from, to)
                // V2ResultClaimRaw.forEach(el => {
                //     return el.poolVersion = "V2"
                // })
                

                // let resultClaimRaw = [...V1ResultClaimRaw, ...V2ResultClaimRaw]
                let resultClaimRaw = [...V1ResultClaimRaw]
                // let resultUnstakeRaw = await _this[contractVersion].queryFilter("UnStake", from, to)
                // console.log(resultUnstakeRaw)
                let V1ResultRefRaw = await _this.V1.queryFilter('ReferralEarn', from, to)
                V1ResultRefRaw.forEach(el => {
                    return el.poolVersion = "V1"
                })
                // let V2ResultRefRaw = await _this.V2.queryFilter('ReferralEarn', from, to)
                // V2ResultRefRaw.forEach(el => {
                //     return el.poolVersion = "V1"
                // })
                // let resultRefRaw = [...V1ResultRefRaw, ...V2ResultRefRaw]
                let resultRefRaw = [...V1ResultRefRaw]


                let claimEvents = resultClaimRaw.map(el => {
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        stakedTokenAddress: el.args[1],
                        tokenAddress: el.args[2],
                        amount: Number(ethers.utils.formatUnits(el.args[3])),
                        stakeId: Number.parseInt(el.args[4]),
                        endTime: Number.parseInt(el.args[5]),
                        transactionHash: el.transactionHash,
                        poolVersion: el.poolVersion
                    }
                })
 
                let refRewardsEvents = resultRefRaw.map(el => {
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        callerAddress: el.args[1],
                        rewardTokenAddress: el.args[2],
                        rewardAmount: Number(ethers.utils.formatUnits(el.args[3])),
                        endTime: Number.parseInt(el.args[4]),
                        transactionHash: el.transactionHash,
                        poolVersion: el.poolVersion
            
                    }
                })

                let userClaimEvents = claimEvents.filter(event => event.userAddress.toLowerCase() == userAddress.toLowerCase());
                let userRefRewardsEvents = refRewardsEvents.filter(event => event.userAddress.toLowerCase() == userAddress.toLowerCase());

                let userEvents = JSON.parse(localStorage.getItem(`${userAddress}`)) || [];

                if (userEvents.length === 0){

                    userEvents = {
                        from,
                        claimEvents: [...userClaimEvents],
                        refReward: [...userRefRewardsEvents]
                    };
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }else{

                    let previousClaimEvents = userEvents.claimEvents || [];
                    let previousRefReward = userEvents.refReward || [];

                    let refReward = [...previousRefReward, ...userRefRewardsEvents];
                    refReward = refReward.reduce(
                      (x, y) => x.findIndex(e => (e.transactionHash == y.transactionHash && e.rewardTokenAddress == y.rewardTokenAddress)) < 0 ? [...x, y]: x, []
                    )

                    userEvents = {
                        from,
                        claimEvents: [...previousClaimEvents, ...userClaimEvents],
                        refReward
                    }
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }
                if(from == blockchainLastBlock) {

                    setTimeout(tick, period)
                    return
                }else if (from + 1000 > blockchainLastBlock) {

                    
                    from = blockchainLastBlock

                }else {

                    from+= 1000

                }
            

                }
                setTimeout(tick,period)
            } catch (error) {
              console.log(error);
              setTimeout(tick, 300)
            }
        }, 300)
        
    }

    async getStakeAmount(userAddress, stakeId, contractVersion) {
        
        let resultRaw = await this[contractVersion].getStakeAmount(userAddress, stakeId);
        
        let result = Number(ethers.utils.formatUnits(resultRaw));
        return result;
    }

    async getRewardForPeriod(stakedAmount, stakedToken, rewardToken, contractVersion){
        try {
            let {V1TotalStake, V2TotalStake} = await this.getTotalStaking(stakedToken);

                V1TotalStake = V1TotalStake == 0 ? stakedAmount : V1TotalStake 
                V2TotalStake = V2TotalStake == 0 ? stakedAmount : V2TotalStake

                if(contractVersion == "V1"){
                    const totalStakingBig = ethers.utils.parseEther(`${V1TotalStake}`, "ether")
                    const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")

                    const rewardRaw = await this[contractVersion].getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                    const reward = (ethers.utils.formatUnits(rewardRaw));

                    return reward
                }else if (contractVersion == "V2"){
                    const totalStakingBig = ethers.utils.parseEther(`${V2TotalStake}`, "ether")
                    const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")

                    const rewardRaw = await this[contractVersion].getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                    const reward = (ethers.utils.formatUnits(rewardRaw));

                    return reward;
                }

        } catch (error) {
            console.log(error);
        }
        
    }


    async getPossibleDailyReward(stakedAmount, stakedToken, rewardToken) {
        try {
            // let {V1TotalStake, V2TotalStake} = await this.getTotalStaking(stakedToken)
            let {V1TotalStake} = await this.getTotalStaking(stakedToken)
            V1TotalStake = V1TotalStake == 0 ? stakedAmount : V1TotalStake 
            // V2TotalStake = V2TotalStake == 0 ? stakedAmount : V2TotalStake

            const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")

            const V1newTotalStaking = Number(stakedAmount) + V1TotalStake;
            const V1newTotalStakingBig = ethers.utils.parseEther(`${V1newTotalStaking}`, "ether");

            // const V2newTotalStaking = Number(stakedAmount) + V2TotalStake;
            // const V2newTotalStakingBig = ethers.utils.parseEther(`${V2newTotalStaking}`, "ether");

            const V1RewardRaw = await this.V1.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V1newTotalStakingBig);
            const V1Reward = (ethers.utils.formatUnits(V1RewardRaw));

            // const V2RewardRaw = await this.V2.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V2newTotalStakingBig);
            // const V2Reward = (ethers.utils.formatUnits(V2RewardRaw));

            return {
                V1Reward,
                // V2Reward
            }

        } catch (error) {
            // console.log(error);
        }
    }

    withoutRound(number, roundTo=2){
        if(roundTo === 2){
            if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }
        }else if(roundTo === 4){
             if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }
    }
    }

    
}